.card-column__amount {
    margin-top: 8px;
}

.card-column__voice {
    height: 5px;
    width: var(--card-width);
    border-radius: var(--border-radius);
    background: var(--card-dark);
}

.card-column__voice.attention {
    background: var(--color-attention);
}
.card-column__voice.negative {
    background: var(--color-negative);
}
.card-column__voice.positive {
    background: var(--color-positive);
}

.card-column__voice + .card-column__voice {
    margin-top: 4px;
}

.card-column__satisfaction + .card-column__satisfaction {
    margin-left: 10px;
}
