.card {
    width: var(--card-width);
    height: var(--card-height);
    background: var(--card-light);
    color: var(--text-color);
    border-radius: var(--border-radius);
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 30px;
    line-height: 42px;
    cursor: pointer;
}

.card:hover {
    opacity: 0.8;
}

.card-dark {
    background: var(--card-dark);
}

.card-admin {
    cursor: auto;
}

.card-admin:hover {
    opacity: 1;
}

.card.attention {
    background: var(--color-attention);
}
.card.negative {
    background: var(--color-negative);
}
.card.positive {
    background: var(--color-positive);
}
