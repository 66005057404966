:root {
  --text-color: #000;
  --card-light: #D2EAFF;
  --card-dark: #8EC9FF;
  --card-width: 40px;
  --card-height: 60px;
  --border-radius: 4px;
  --color-attention: #f0c330;
  --color-negative: #ff5c5c;
  --color-positive: #87b965;
}

.app {
   padding: 20px;
}

@media screen and (max-width: 500px) {
  .app {
    padding: 10px;
  }
}
